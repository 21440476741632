<template>
  <div class="com-user-CommonProblen">
    <a-row>
      <a-col :span="12" v-for="(item, index) in source.question" :key="index">
        <a-tooltip>
          <template slot="title"> {{ item.title }} </template>
          <router-link class="cursor-pointer" :to="`/docs/help?id=${item.id}`" target="_blank">
            <cs-icon style="color: #e5e5e5" name="icon-changjianwenti" />
            <span class="title text-decoration-underline">{{
              item.title
            }}</span>
          </router-link>
        </a-tooltip>
      </a-col>
      <a-col :span="24">
        <a-button class="but" @click="moreClick"
          >了解更多<a-icon type="arrow-right"
        /></a-button>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      source: {
        question: [],
      },
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.api.service
        .fpc_oapi_problem_selectProblemPreview({}, {})
        .then(async (res) => {
          await this.api.toast(res, 0);
          this.source.question = res.data;
        });
    },
    moreClick() {
      this.$router.push("/docs/help");
    },
  },
};
</script>

<style lang="less" scoped>
.com-user-CommonProblen {
  @media screen and(max-width: 1600px) {
    width: 700px;
  }
  @media screen and(max-width: 1366px) {
    width: 550px;
  }
  .ant-col-12 {
    padding: 5px 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  a {
    font-size: 14px;
    color: #374567;
    .icon {
      font-size: 19px;
      margin-right: 20px;
    }
    &:hover {
      opacity: 0.7;
    }
    .title {
      text-decoration: underline;
      margin-left: 10px;
    }
  }
  .but {
    margin-top: 10px;
    margin-left: 30px;
    color: @primary-color;
    background-color: #fff;
    border-color: @primary-color;
    &:hover {
      opacity: 0.7;
    }
  }
}
</style>